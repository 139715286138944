
import {computed, defineComponent, onMounted, reactive, ref, watch} from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {Integration, Thirdparty} from "@/store/modules/ApiModule";
import thirdparties from "@/core/data/thirdparties";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {useStore} from "vuex";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "add-integration-modal",
  components: {},
  props: {
    integration: {
      type: Object as () => Integration,
      required: true
    }
  },
  setup(props) {
    const formRef = ref<null | HTMLFormElement>(null);
    const fileRef = ref<null | HTMLInputElement>(null);
    const addIntegrationModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const formData = reactive<Integration>({
      id: 0, thirdparty_id: 1, user: "", password: "", status: "", data: {}, two_factor_code: "", error: "", thirdparty: {} as Thirdparty, cookie: ""
    });
    const store = useStore();

    watch(props.integration, (newValue, oldValue) => {
      Object.assign(formData, newValue);
      if(formRef.value){
        formRef.value.clearValidate();
      }
      if(fileRef.value){
        fileRef.value.value = '';
      }
    });

    const onChangeFile = (event) => {
      const fr = new FileReader();
      fr.onload = function(){
        const jsonString = fr.result;
        if(jsonString){
          formData.cookie = JSON.parse(jsonString.toString());
        }
      }
      fr.readAsText(event.target.files[0]);
    };


    const rules = ref({
      user: [
        {
          required: true,
          message: "User is required",
          trigger: "change",
        }
      ],
      thirdparty: [
        {
          required: true,
          message: "Third Party is required",
          trigger: "change",
        }
      ],
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          ApiService.setHeader();
          return ApiService.post("integrations", formData)
              .then((response) => {
                loading.value = false;
                store.dispatch(Actions.LOAD_INTEGRATIONS);
                if(response.data.status == "2FA"){
                  formData.id = response.data.id;
                  Swal.fire({
                    text: "Two Factor Authentication enabled. Please provide code.",
                    icon: "warning",
                    buttonsStyling: false,
                    confirmButtonText: "Close",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                  });
                }
                else {
                  let icon = "success"
                  let message = "Integration has been saved!"
                  let closeWindow = true
                  if(response.data.status == "PENDING") {
                    icon = "warning"
                    if (formData.two_factor_code == null) {
                      message = "Integration is being checked by a background process. If it is protected by 2FA, please introduce the code received, if not, you can close the form. You have 5 minutes for introducing the 2FA code after it arrives. After introducing the code, wait 5 minutes and reload the list of integrations to check status."
                      closeWindow = false
                    } else {
                      message = "System is checking the 2FA code ... Reload list of integrations in 1-2 minutes to check the status."
                    }
                  }
                  Swal.fire({
                    text: message,
                    icon: icon,
                    buttonsStyling: false,
                    confirmButtonText: "Close",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                  }).then(() => {
                    if(formRef.value){
                      formRef.value.resetFields();
                    }
                    if(closeWindow) hideModal(addIntegrationModalRef.value);
                  });
                }
              })
              .catch((error) => {
                loading.value = false;
                Swal.fire({
                  text: error.response.data.message,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Close",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
              });
        } else {
          loading.value = false;
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    return {
      fileRef,
      formData,
      rules,
      thirdparties,
      submit,
      onChangeFile,
      formRef,
      loading,
      addIntegrationModalRef,
    };
  },
});
