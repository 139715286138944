import {Thirdparty} from "@/store/modules/ApiModule";

const thirdparties: Array<Thirdparty> = [
    {
        id: 1,
        name: "Appsflyer"
    },
    {
        id: 2,
        name: "AppRadar"
    },
    {
        id: 6,
        name: "Google Play Developer Console"
    },
    {
        id: 7,
        name: "Appstore Connect"
    },
    {
        id: 8,
        name: "AppTweak"
    }
];

export default thirdparties;
